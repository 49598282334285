import React, { useEffect, useState, useMemo } from 'react'
import VideoJS from './player'
import videojs from 'video.js';
import { useLocation } from 'react-router-dom';
import 'video.js/dist/video-js.css';
import { useHistory } from 'react-router-dom';
import "videojs-markers"
import { useDispatch, useSelector } from 'react-redux';
// import { advertise_seen_count_change } from '../actions/MovieAction';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: "70vh",
  width: 400,
  bgcolor: 'rgb(25,25,25)',
  border: '2px solid rgb(255,255,255,0.5)',
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

require("@silvermine/videojs-quality-selector")(videojs);

export default function VideoFile() {

  const playerRef = React.useRef(null);
  const history = useHistory()
  const location = useLocation()



  const videoJsOptions = {
    autoplay: true,
    loop: false,
    controls: true,
    responsive: true,
    fluid: true,
    muted: false,
    textTrackSettings: false,
    // playbackRates: [0.5, 1, 1.5, 2],
    userActions: { hotkeys: true },
    controlBar: {
      qualitySelector: true,
      fullscreenToggle: true,
      volumePanel: { inline: false },
      pictureInPictureToggle: false,
      skipButtons: {
        forward: 10,
        backward: 10
      }

    },


    sources: [
      {
        src: `${location?.state?.data?.Trailer}`,
        type: location?.state?.data?.Trailer.split('.').pop().toLowerCase()=='m3u8' ? "application/x-mpegURL" :"video/mp4",
        label: "360p",
      }

    ]

  };

  const handlePlayerReady = (player) => {
    var currentTime = 0;
    playerRef.current = player;
    // player?.addChild("QualitySelector")
    //  player.addChild("PlaybackRateMenuButton")
    player.addChild("fullscreenToggle")
    player.controlBar.removeChild("QualitySelector")
    //  player.controlBar.removeChild("PlaybackRateMenuButton")
    player.controlBar.removeChild("fullscreenToggle")
    player.titleBar.removeClass('vjs-hidden')
    player.titleBar.addChild('button', {
      controlText: `${location?.state?.data?.movie_name}`,
      className: 'vjs-visible-text',
      position: 2,
    });
    player.markers({
      markerStyle: {
        'position': 'absolute',
        'height': '4px',
        'width': '4px',
        'top': "0",
        'background-color': 'yellow'
      },
    })


    player.addChild('button', {
      className: 'go-back',
      clickHandler: function (event) {
        history.goBack()
      }
    });

    player.on("userinactive", function () {
      document.getElementsByClassName("vjs-quality-selector")[0].style.display = "none"
      document.getElementsByClassName("vjs-fullscreen-control")[0].style.display = "none"
      document.getElementsByClassName(" go-back")[0].style.display = "none"



    })
    player.on("useractive", function () {
      document.getElementsByClassName("vjs-quality-selector")[0].style.display = "block"
      document.getElementsByClassName("vjs-fullscreen-control")[0].style.display = "block"
      document.getElementsByClassName(" go-back")[0].style.display = "block"
    })

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });



    player.on('dispose', async () => {

    });


    player.on("seeking", () => {

      currentTime = player?.currentTime()
    })

    player.on('loadeddata', () => {
      if (player?.currentTime() != currentTime)
        player?.currentTime(currentTime)

      player.play()

      document.getElementsByClassName("vjs-quality-selector")[0].classList.remove('vjs-hidden')
    })


    player.on("qualityRequested", () => {
      if (player.play()) {
        player.pause()
      }
      const temp = document.getElementsByClassName("vjs-menu-item vjs-selected")[3].getElementsByClassName("vjs-menu-item-text")[0].innerText
      player.src(videoJsOptions.sources.find((ele) => ele?.label == temp))
      for (let i = 0; i < videoJsOptions.sources.length; i++) {
        videoJsOptions.sources[i].selected = (videoJsOptions.sources[i]?.label == temp)
      }
      currentTime = player?.currentTime()
      player.load();

    })


  };



  return (
    <>

      <div className='video-parent video trailer-file-qty'>
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
      </div>
      {/* <div onClick={() => history.goBack()} className='go-back' ><img src={left_arrow} width={"40px"}/></div> */}

    </>
  )
}


