import React, { useState, useEffect, useMemo } from 'react'
import { Container, Button, Row, Col, Form } from 'react-bootstrap'

import { Link, useHistory } from 'react-router-dom'
import "./form.css"
// rtl 
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { rtlModeAction, getRtlMode } from '../../../../store/mode/rtlmode'
import { useDispatch } from 'react-redux';
import bg from "../../../../assets/images/signup_background.jpg"
import user_pic from "../../../../assets/images/user/user.jpg"
import { IMAGE_ENDPOINT } from '../../../../constant/ApiList';
// import { user_login_list } from '../../../../actions/HomeActions';
import * as HomeAction from '../../../../actions/HomeActions';

import { ROUTES } from '../../../../constant/routes';
import device_restristion from '../../../../assets/regal_images/Device Restriction.png'
import transaction from '../../../../assets/regal_images/transaction-history.png'
import change_password from '../../../../assets/regal_images/Change Pswd.png'
import axios from "axios";
import { CircularProgress } from '@mui/material';
const mapStateToProps = (state) => {
    return {
        rtlMode: getRtlMode(state)
    };


}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            rtlModeAction
        },
        dispatch
    )
})
const Device_Management = (props) => {
    const user = JSON.parse(sessionStorage.getItem('user'))
    const profile = JSON.parse(sessionStorage.getItem('profile'))
    const dispatch = useDispatch()
    let history = useHistory()
    const [save, setSave] = useState(false);
    const [device, setDevice] = useState([])
    const user_list = useSelector((state) => state.home.user_login_list)
    const { user_logout, user_login_list, forgot_password_send_otp } = bindActionCreators(HomeAction, dispatch)
    const [loader, setLoader] = useState(false)
    const [ipAddress, setIpAddress] = useState();
    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");

        setIpAddress(res.data.ip)
    };
    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        (async () => {
            let userObj = {
                user_id: user?.id
            }
            const resData = await user_login_list(userObj)
            setDevice(resData?.data)
        })();
        // dispatch(user_login_list({ user_id: user?.id }))

    }, [save])
    const handleLogout = async (ele) => {
        let userObj = {
            user_id: ele?.user,
            login_type: ele?.login_type,
            id: ele?.id
        }
        const deleteUser = await user_logout(userObj)
        setSave(!save)
        // dispatch(user_logout({ ...ele, user_id: ele?.user }))
    }
    const handleLogoutAllDevice = async () => {
        // setTimeout(() => {
        //     setSave(!save)
        // }, 2000);
        // device?.map((ele) =>
        //     ele?.ip_address != ipAddress && dispatch(user_logout({ ...ele, user_id: ele?.user }))
        // )
        setLoader(true)
        let userObj = {
            username: user?.email
        }
        const sendOTP = await forgot_password_send_otp(userObj)
        if (sendOTP?.statuscode == 200) {
            setLoader(false)
            history.push(ROUTES.logoutwithOTP, { device: device })
        }
        else {
            setLoader(false)
        }
    }
    const user_logout_message = useSelector((state) => state.home.user_logout_message)
    const goBack = () => {
        history.push(ROUTES.my_account)
    }


    return (
        <>

            {/* <section className="sign-in-page" style={{ backgroundImage: "url(" + bg + ")" }}> */}
            <section className="sign-in-page" style={{ marginTop: "2rem" }}>

                <Container >
                    <Row className="justify-content-center align-items-center height-self-center">
                        <Col lg="7" md="12" className="align-self-center">
                            <div className="sign-user_card ">
                                <div className="sign-in-page-data">
                                    <div className="sign-in-from w-100 m-auto">


                                        <p style={{ textAlign: "center", marginBottom: "1rem", fontWeight: "400", fontSize: "24px" }}>Device Management</p>
                                        <div style={{ display: "flex", justifyContent: "space-between",alignItems:"center" }}>
                                            <span>Connected Devices : {device?.length}</span>
                                            <div style={{ display: "flex", alignItems: "center", alignContent: "center" }}>
                                                {
                                                    !loader ?
                                                        <span className="" onClick={handleLogoutAllDevice} style={{ cursor: "pointer" }}>Logout All Device</span>
                                                        :
                                                        <CircularProgress />

                                                }
                                            </div>

                                        </div>
                                        <p style={{ textAlign: "center" }}>{user_logout_message?.message}</p>
                                        <div style={{ height: "auto", overflowY: "auto", overflowX: "hidden" }}>

                                            <Row >
                                                <Col md="12" >
                                                    <div style={{ padding: "1rem" }}>
                                                        <div className="media align-items-center">
                                                            <div className="right-icon">
                                                                <i className="fa fa-desktop" aria-hidden="true" style={{ fontSize: "20px" }}></i>

                                                            </div>
                                                            <div className="media-body ml-3" >
                                                                <p className="my-0 mb-0">Web Browser</p>
                                                                <p className="my-0 " style={{ fontSize: "10px" }}>This Device</p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {device?.map((ele, index) =>
                                                ele?.ip_address != ipAddress && <Row key={index}>
                                                    <Col md="12" >
                                                        <div style={{ borderTop: "grey 1px solid", padding: "1rem" }}>
                                                            <div className="media align-items-center">
                                                                <div className="right-icon">
                                                                    {/* <img src={change_password} alt='acc' className='menu-img' /> */}
                                                                    <i className={ele?.login_type == "web" ? "fa fa-desktop" : "fa fa-mobile"} aria-hidden="true" style={{ fontSize: ele?.login_type == "web" ? "20px" : "40px" }}></i>

                                                                </div>
                                                                <div className="media-body ml-3" >
                                                                    <p className="my-0 mb-0">{ele?.login_type == "web" ? "Web Browser" : ele?.deviceId}</p>
                                                                    <p className="my-0 " style={{ fontSize: "10px" }}>{ele?.location}</p>

                                                                </div>
                                                                <div className="right-icon" >
                                                                    <p className="my-0 " onClick={() => handleLogout(ele)} style={{ fontSize: "10px", cursor: "pointer" }}>Logout</p>

                                                                </div>
                                                            </div>



                                                        </div>
                                                    </Col>
                                                </Row>)}

                                        </div>
                                        <div style={{ cursor: "pointer" }} className='mt-3' onClick={goBack}><i className="fa fa-arrow-left" aria-hidden="true"></i>  Back</div>


                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Device_Management)