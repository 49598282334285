import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as PaymentAction from '../actions/PaymentActions'
import { Container } from 'react-bootstrap'
import { Box, CircularProgress, Modal, Typography } from '@mui/material'
import payment from '../assets/regal_images/payment.png'
import failed from '../assets/regal_images/failed.png'
import { ROUTES } from '../constant/routes'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const styleforAlert = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "100vh",
    width: 600,
    bgcolor: 'rgb(0,0,0)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
}

export default function Phonepe() {
    const dispatch = useDispatch()
    const { getPhonePePaymentStatus } = PaymentAction
    const [open, setOpen] = useState(true)
    const [msg, setMsg] = useState({})
    const history = useHistory()
    let len = (window.location.href).split('/').length
    // console.log((window.location.href).split('/').length)
    // console.log(len)
    useEffect(() => {
        (async () => {
            const resData = await getPhonePePaymentStatus({ transaction_id: (window.location.href).split('/')?.[len - 1] })
            setMsg(resData)
        })();
    }, [])

    const handleRedirect = () => {
        if (msg?.metadata?.movie) {
            history.push(`${ROUTES.moviedetails}/${msg?.metadata?.movie?.slug}`)
        }
        else if (msg?.metadata?.series) {
            history.push(`${ROUTES.seriesDetails}/${msg?.metadata?.series?.slug}`)

        }
        else {
            history.push('/')
        }
    }
    return (
        <div className='m-profile'>
            <Container>
                {
                    msg ?
                        <Modal
                            open={open}
                            // onClose={() => setOpen(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >

                            <Box className="responsive-success" sx={styleforAlert}>
                                <div>

                                </div>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <img src={(msg?.status?.code == "PAYMENT_ERROR" || msg?.status?.code == "PAYMENT_PENDING") ? failed : payment} alt='regal_logo' style={{ width: "80px" }} />
                                    {/* <h4>Transaction Success</h4> */}
                                </div>
                                {/* <div style={{ position: "absolute", top: "5px", right: "5px" }}>
                            <div onClick={() => setOpen(false)}>
                                <HighlightOffIcon style={{ height: "40px", cursor: "pointer" }} />
                            </div>
                        </div> */}
                                {/* <hr /> */}
                                <Typography id="modal-modal-description" sx={{ mt: 2, fontWeight: "700", fontSize: "20px", textAlign: "center" }}>
                                    {(msg?.status?.code == "PAYMENT_ERROR" || msg?.status?.code == "PAYMENT_PENDING") ? "Transaction Failed" : "Transaction Completed Successfully"}
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2, fontWeight: "700", fontSize: "20px", textAlign: "center" }}>
                                    {msg?.metadata?.plan ? (msg?.metadata?.series?.series_name || msg?.metadata?.movie?.movie_name) : ""}
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 1, fontWeight: "700", fontSize: "25px", textAlign: "center" }}>
                                    ₹ {(((msg?.status?.data?.amount) / 100).toFixed(2)) || Number(msg?.metadata?.payment_amount).toFixed(2)}
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2, fontWeight: "700", fontSize: "15px", textAlign: "center" }}>
                                    Transaction ID <br /> {(msg?.status?.data?.merchantTransactionId) || (msg?.metadata?.payment_id)}
                                </Typography>
                                <div className='pt-3' style={{ display: "flex", justifyContent: "center" }}>
                                    <button className="btn btn-hover mb-1 iq-button" style={{ marginBottom: "10px" }} onClick={handleRedirect}>
                                        Continue
                                    </button>

                                </div>
                            </Box>
                        </Modal>
                        :
                        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                            <CircularProgress sx={{ color: "#ba0f34" }} />
                        </div>
                }

            </Container>
        </div>
    )
}
