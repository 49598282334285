import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as MovieAction from '../../../../actions/MovieAction'
import { Card, Container } from 'react-bootstrap'
import { CircularProgress, Grid } from '@mui/material'
import { ROUTES } from '../../../../constant/routes'
import { IMAGE_ENDPOINT } from '../../../../constant/ApiList'
import { Link } from 'react-router-dom'
import empty from '../../../../assets/regal_images/empty-box (1).png'
import rent_icon from '../../../../assets/regal_images/Rent (1).png'

export default function ShortFilms() {
    const dispatch = useDispatch()
    const { getAllMovies } = bindActionCreators(MovieAction, dispatch)
    const [movies, setMovies] = useState([])

    useEffect(() => {
        (async () => {
            let userObj = {
                category_name: "SHORT STORIES"
            }
            const resData = await getAllMovies(userObj)
            setMovies(resData?.data)
        })();
    }, [])

    return (
        <>
            <section className="m-profile setting-wrapper">
                <Container>
                    <h4 className="main-title mb-5 mt-3">Short Films</h4>
                    <Grid container spacing={2}>
                        {
                            movies?.length ?
                                movies?.map((data, index) => (
                                    <Grid item lg={2} xl={2} md={2} sm={4} xs={6} key={index} className='grid-img-cls trending-info align-items-center w-100 animated fadeInUp iq-ltr-direction'>
                                        <Link to={`${ROUTES.moviedetails}/${data?.slug}`}>
                                            <img src={`${IMAGE_ENDPOINT + data?.movie_thumbnail}`} className="thumb-img-filter" alt={data?.movie_name} />
                                            {/* {data?.movie_access == "TVOD" && <div className="block-icon-tvod-list">
                                                <img src={rent_icon} width={"75px"} />


                                            </div>} */}
                                            <Card className='hover-class'>
                                                <Card.Img variant="top" src={`${IMAGE_ENDPOINT}/${data?.movie_poster}`} />
                                                <Card.Body style={{ marginTop: "-1rem" }}>
                                                    <span style={{ color: "#ffffff", fontSize: "13px" }}>{data?.movie_name}
                                                    </span><br />
                                                    <div style={{ marginTop: "-0.3rem" }}>
                                                        <span style={{ color: "#ffffff", fontSize: "10px" }}>Duration: {data?.movie_duration}
                                                        </span>
                                                        <p style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: data?.movie_description?.length <= 60 ? data?.movie_description : data?.movie_description?.substring(0, 60) + "..." }}></p>
                                                        <div style={{ display: "flex", background: "#ffffff", color: "#000000", fontWeight: "bolder", borderRadius: "0.5rem", }}>
                                                            <div style={{ fontSize: "13px", margin: "0 auto", padding: "5px" }}><i className="fa fa-info" aria-hidden="true"></i>  Details</div>
                                                        </div>
                                                    </div>

                                                </Card.Body>
                                            </Card>


                                        </Link>
                                    </Grid>
                                ))
                                :
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        {/* <img src={empty} alt='empty' style={{ height: "60px" }} />
                                        <p className='pt-4'> Sorry, No result found.</p> */}
                                        <CircularProgress />
                                    </div>
                                </Grid>
                        }

                    </Grid>
                </Container>
            </section>
        </>
    )
}
