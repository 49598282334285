import axios from 'axios'
import { GET_SUBSCRIPTION_DETAILS_API, payment_api, transaction_history_api, change_password_api, check_promocode_api, PHONEPE_API, PHONEPE_STATUS_API } from '../constant/ApiList'
import { GET_SUBSCRIPTION_DETAILS, GET_SUBSCRIPTION_DETAILS_ERROR, PAYMENT_MESSAGE, TRANSACTIONS, PROMOCODE } from '../Actiontype/ActionType'

export const getSubscriptionDetails = (userObj) => {
  return async (dispatch) => {
    return await axios
      .post(GET_SUBSCRIPTION_DETAILS_API, userObj)
      .then((res) => {
        dispatch({
          type: GET_SUBSCRIPTION_DETAILS,
          payload: res.data
        })
        return res.data
      }).catch((error) => {
        dispatch({
          type: GET_SUBSCRIPTION_DETAILS_ERROR
        })
        return error.response
      })
  }
}
export const payment = (formData) => async (dispatch) => {
  try {
    const { data } = await payment_api(formData);
    dispatch({ type: PAYMENT_MESSAGE, payload: data });


  } catch (error) {
  }
};
export const check_promocode = (formData) => async (dispatch) => {
  try {
    const { data } = await check_promocode_api(formData);
    dispatch({ type: PROMOCODE, payload: data });


  } catch (error) {
  }
};

export const transaction_history = (formData) => async (dispatch) => {
  try {
    const { data } = await transaction_history_api(formData);
    dispatch({ type: TRANSACTIONS, payload: data });
    dispatch({ type: PROMOCODE, payload: null });


  } catch (error) {
  }
};

export const phonepePayment = async (userObj) => {
  return await axios
    .post(PHONEPE_API, userObj)
    .then((res) => {

      return res.data
    }).catch((error) => {

      return error.response
    })

}

export const getPhonePePaymentStatus = async (userObj) => {
  return await axios
    .post(PHONEPE_STATUS_API, userObj)
    .then((res) => {

      return res.data
    }).catch((error) => {

      return error.response
    })
}