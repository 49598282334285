import axios from 'axios';

const API_ENDPOINT = 'https://webapi.premiumflix.net/api'
export const IMAGE_ENDPOINT = 'https://webapi.premiumflix.net'
const API = axios.create({ baseURL: 'https://webapi.premiumflix.net/api' });

export const GET_SLIDER_DATA_API = API_ENDPOINT + "/web_movie_banner/"

//homedata all
export const GET_ALL_HOME_DATA_API = API_ENDPOINT + "/web_dashboard/"

//LIVE_STREAMING_DASHBOARD
export const LIVE_STREAMING_DASHBOARD_DATA_API = API_ENDPOINT + "/live_streaming_dashboard/"
// Stream details api
export const GET_STREAM_DETAILS_API = API_ENDPOINT + "/live-stream"

// Movie details api
export const GET_MOVIE_DETAILS_API = API_ENDPOINT + "/movie-details"

//series details 
export const GET_SERIES_DETAILS_API = API_ENDPOINT + "/series-details"

//episode details
export const GET_EPISODE_DETAILS_API = API_ENDPOINT + "/data_episode_details/"

//movies by language
export const GET_ALL_MOVIES_BY_LANGUAGE_API = API_ENDPOINT + "/movie_list_by_language/"

//movies by genre
export const GET_ALL_MOVIES_BY_GENRE_API = API_ENDPOINT + "/movie_list_by_genre/"

//series api
export const GET_ALL_SERIES_API = API_ENDPOINT + "/all_series_web/"

//Continue watching
export const GET_CONTINUE_WATCHING_API = API_ENDPOINT + "/continue_watching_web/"
export const ADD_CONTINUE_WATCHING_API = API_ENDPOINT + "/create_continue_watching/"
export const DELETE_CONTINUE_WATCHING_API = API_ENDPOINT + "/continue_watching_delete/"

//Subscription plan api
export const GET_SUBSCRIPTION_DETAILS_API = API_ENDPOINT + "/web_subscription_details/"
export const PHONEPE_API = API_ENDPOINT + "/payment_integrate/"
export const PHONEPE_STATUS_API = API_ENDPOINT + "/check_payment_status/"


//watchlist
export const GET_USER_WATCHLIST_API = API_ENDPOINT + "/user_wishlist_list/"
export const ADD_MOVIE_IN_WATCHLIST_API = API_ENDPOINT + "/user_wishlist_create/"
export const REMOVE_MOVIE_FROM_WATCHLIST_API = API_ENDPOINT + "/wishlist_delete/"

//search
export const SEARCH_API = API_ENDPOINT + "/search/"

//Resister
export const REGISTER_API = (formData) => API.post('/web_register/', formData);
//resend_otp
export const resend_otp_api = (formData) => API.post('/resend_otp/', formData);
//Login
export const LOGIN_API = (formData) => API.post('/web_user_login/', formData);
//Logout
export const LOGOUT_API = (formData) => API.post('/web_logout/', formData);

//List of movies
export const GET_ALL_MOVIES_API = API_ENDPOINT + '/data_by_category_web/'
//OTP
export const OTP_API = (formData) => API.post('/otp_verification/', formData);
//AVATARS List
export const all_avatar_list_api = (formData) => API.post('/all_avatar_list/', formData);
//Profile Create
export const profile_create_api = (formData) => API.post('/profile_create/', formData);
//profile_pin_verification
export const profile_pin_verification_api = (formData) => API.post('/profile_pin_verification/', formData);
//forgot_profile_pin
export const forgot_profile_pin_api = (formData) => API.post('/forgot_profile_pin/', formData);
//Profile Edit
export const profile_edit_api = (formData) => API.post('/profile_update/', formData);
//Profile delete
export const profile_delete_api = (formData) => API.post('/profile_delete/', formData);
//Profile Select
export const user_profile_list_api = (formData) => API.post('/user_profile_list/', formData);
//Complaint Create
export const complaint_create_api = (formData) => API.post('/complaint_create/', formData);
//privacy policy
export const privacy_policy_api = (formData) => API.post('/privacy_policy/', formData);
//terms_and_conditions
export const terms_and_conditions_api = (formData) => API.post('/terms_and_conditions/', formData);
//refund_policy
export const refund_policy_api = (formData) => API.post('/refund_policy/', formData);
//about_us
export const about_us_api = (formData) => API.post('/about_us/', formData);
// user_details
export const user_details_web_api = (formData) => API.post('/user_details_web/', formData);

// edit_user_details
export const edit_user_details_api = (formData) => API.post('/edit_user_details/', formData);
// change_password
export const change_password_api = (formData) => API.post('/change_password/', formData);
// update_password
export const update_password_api = (formData) => API.post('/update_password/', formData);
// forgot_password_send_otp
export const forgot_password_send_otp_api = (formData) => API.post('/forgot_password_send_otp/', formData);
// forgot_password_verify_otp
export const forgot_password_verify_otp_api = (formData) => API.post('/forgot_password_verify_otp/', formData);
// payment_api
export const payment_api = (formData) => API.post('/transaction_create/', formData);

// transaction_history_api
export const transaction_history_api = (formData) => API.post('/user_transaction_history/', formData);
// check_promocode_api
export const check_promocode_api = (formData) => API.post('/check_promocode/', formData);
// advertise_seen_count_change_api
export const advertise_seen_count_change_api = (formData) => API.post('/advertise_seen_count_change/', formData);
// user_notification
export const user_notification_api = (formData) => API.post('/user_notification/', formData);
// notification_read_status_update
export const notification_read_status_update_api = (formData) => API.post('/notification_read_status_update/', formData);

// user_login_list
export const user_login_list_api = (formData) => API.post('/user_login_list/', formData);
// user_logout
export const user_logout_api = (formData) => API.post('/user_logout/', formData);
// user_login_status
export const user_login_status_api = (formData) => API.post('/user_login_status/', formData);
// change_running_status
export const change_running_status_api = (formData) => API.post('/change_running_status/', formData);

//liked video
export const LIKED_CONTENT_API = API_ENDPOINT + "/movie_like_dislike/"
export const LIKED_SERIES_API = API_ENDPOINT + "/series_like_dislike/"

//movie count
export const LIVE_MOVIE_COUNT_API = API_ENDPOINT + "/live_movie_view_count/"

//movie 72 hours
export const SET_TVOD_MOVIE_HOURS_API = API_ENDPOINT + "/set_tvod_movie_time/"

//recommended movies
export const GET_RECOMMENDED_MOVIES_API = API_ENDPOINT + "/recommanded_movie/"

//Leaving soon 
export const GET_LEAVING_SOON_MOVIES_API = API_ENDPOINT + "/leaving_movie_soon/"