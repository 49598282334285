import React, { useState, useEffect, useMemo } from 'react'
import { Container, Button, Row, Col, Form, Dropdown, DropdownButton } from 'react-bootstrap'

import { Link, useHistory } from 'react-router-dom'
import "./form.css"
// rtl 
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { rtlModeAction, getRtlMode } from '../../../../store/mode/rtlmode'
import { useDispatch } from 'react-redux';
import { complaint_create, user_details_web } from "../../../../actions/HomeActions"
import { useSelector } from 'react-redux';
import bg from "../../../../assets/images/signup_background.jpg"
import Alert from 'react-bootstrap/Alert';
import { IMAGE_ENDPOINT } from '../../../../constant/ApiList';
import { toast } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import ChatBot from 'react-simple-chatbot';
import { Grid } from '@mui/material';
import { ROUTES } from '../../../../constant/routes';


import GroupIcon from '@mui/icons-material/Group';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DevicesIcon from '@mui/icons-material/Devices';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const mapStateToProps = (state) => {
    return {
        rtlMode: getRtlMode(state)
    };


}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            rtlModeAction
        },
        dispatch
    )
})

const theme = {
    background: '#000000',
    headerBgColor: '#272727',
    headerFontSize: '20px',
    botBubbleColor: '#272727',
    headerFontColor: 'white',
    botFontColor: 'white',
    userBubbleColor: '#1d2f41',
    userFontColor: 'white',
};

const config = {
    botAvatar: "https://t4.ftcdn.net/jpg/05/11/55/91/360_F_511559113_UTxNAE1EP40z1qZ8hIzGNrB0LwqwjruK.jpg",
    floating: true,
};
const Complaint = (props) => {
    const user = JSON.parse(sessionStorage.getItem('user'))
    const dispatch = useDispatch()
    let history = useHistory()
    const [show, setShow] = useState(false);
    const [messageTimeout, setMessageTimeout] = useState(false)
    const profile = JSON.parse(sessionStorage.getItem('profile'))
    const user_details = useSelector((state) => state.home.user_details?.data)
    const [selectedValue, setSelectedValue] = useState("Select Complaint Type")
    const [image, setImage] = useState("")


    useEffect(() => {
        dispatch(user_details_web({ user_id: user?.id }))

        const rtlMode = sessionStorage.getItem('rtl-mode');
        if (rtlMode === null) {
            props.rtlModeAction(props.rtlMode)
        }
        else {
            props.rtlModeAction(rtlMode);
        }
    }, [])

    const steps = [
        {
            id: '0',
            message: 'Hey there!',
            trigger: '1',
        }, {
            id: '1',
            message: 'Please write your name',
            trigger: '2'
        }, {
            id: '2',
            user: true,
            trigger: '3',
        }, {
            id: '3',
            message: " hi {previousValue}, how can I help you?",
            trigger: 4
        }, {
            id: '4',
            options: [
                { value: 1, label: ' Is there a free trial available for the subscription?', trigger: 'FreeTrial' },
                { value: 2, label: 'What devices can I use to stream content on your platform?', trigger: 'OttDevices' },
                { value: 3, label: ' Are subtitles available for all content?', trigger: 'subtitles' },
                { value: 4, label: 'My payment failed. What should I do?', trigger: 'paymenFailed' },
                { value: 5, label: 'Exit', trigger: 'end' },
            ],
            // end: true
        },
        {
            id: 'FreeTrial',
            message: "Yes, we offer a 3-days free trial for new users. You can explore our content during this period to see if our platform meets your preferences.",
            trigger: 3
        },
        {
            id: 'OttDevices',
            message: 'You can stream our content on various devices such as smart TVs, smartphones, tablets, and computers. Our app is available on iOS, Android, and Smart TV platforms.',
            trigger: 3,
        },
        {
            id: 'subtitles',
            message: ' Yes, we provide subtitles for a wide range of content in multiple languages. You can customize the subtitle settings in the playback options.',
            trigger: 3,
        },
        {
            id: 'paymenFailed',
            message: 'I am sorry to hear that. Payment issues can sometimes occur. Please double-check your payment details and ensure that your card information is accurate. If the problem persists, you may want to contact your bank or try an alternative payment method.',
            trigger: 3,
        },
        {
            id: 'end',
            message: 'Thanks for chatting!',
            end: true,
        },
    ];


    const handleComplaintSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        data.append("name", user?.firstName)
        data.append("user", user?.id)
        data.append("email", user?.email)
        data.append("mobileNumber", user?.mobileNumber)
        data.append("complaint_type", selectedValue)
        data.append("image", image)

        if (selectedValue == "Select Complaint Type") {
            toast.error("Please select complaint type")
        }
        else {
            dispatch(complaint_create(data));
            setSelectedValue("Select Complaint Type")
            setImage("")
            document.getElementsByName("description")[1].value = ""
        }
    }
    const complaint_message = useSelector((state) => state.home.complaint_message)
    useMemo(() => {

        setMessageTimeout(true)

        setTimeout(() => { setMessageTimeout(false) }, 2000)
    }, [complaint_message])

    const handleValue = (e) => {
        setSelectedValue(e.target.value)
    }
    return (
        <>

            {/* <section className="sign-in-page" style={{backgroundImage:"url("+bg+")"}}> */}

            <section className="m-profile setting-wrapper">
                <Container>
                    <Row style={{ alignItems: "center" }}>
                        <Col lg="4" className="">
                            <h5 className="main-title mb-4">Help & Support</h5>

                            <div className="sign-user_card text-center">
                                <img style={{ height: "150px" }} src={IMAGE_ENDPOINT + profile?.avatar?.avatarImage} className="rounded-circle img-fluid d-block mx-auto mb-3" alt="user" />
                                <h4 className="mb-3">{user_details?.firstName + " " + user_details?.lastName}</h4>
                                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p> */}
                                <p>{user_details?.email}</p>
                                <p>{user_details?.mobileNumber}</p>
                                {/* <Link to="#" className="edit-icon text-primary">Edit</Link> */}
                            </div>
                        </Col>
                        <Col lg="8">
                            <div className='mb-2' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                                <h5 className="main-title pt-3">Submit your query to Premiumflix</h5>

                            </div>
                            <div className="" style={{ border: "1px solid #ffffff", padding: "20px", borderRadius: "0.7rem" }}>
                                <Form onSubmit={(event) => handleComplaintSubmit(event)}>

                                    <Row >

                                        <Col md="6" >
                                            <Form.Group className='btn-change drpdwn-colour'>
                                                <Form.Label style={{ display: "flex", color: "white" }}> Select Complaint type</Form.Label>
                                                <DropdownButton id="dropdown-item-button" className='mt-2' title={selectedValue}>
                                                    <Dropdown.Item as="option" style={{ cursor: "pointer", color: "white" }} value="Player not working" onClick={(e) => handleValue(e)}>Player not working</Dropdown.Item>
                                                    <Dropdown.Item as="option" style={{ cursor: "pointer", color: "white" }} value="Subscription not working" onClick={(e) => handleValue(e)}>Subscription not working</Dropdown.Item>
                                                    <Dropdown.Item as="option" style={{ cursor: "pointer", color: "white" }} value="Content is not appropriate" onClick={(e) => handleValue(e)}>Content is not appropriate</Dropdown.Item>
                                                    <Dropdown.Item as="option" style={{ cursor: "pointer", color: "white" }} value="Profile related issue" onClick={(e) => handleValue(e)}>Profile related issue</Dropdown.Item>
                                                    <Dropdown.Item as="option" style={{ cursor: "pointer", color: "white" }} value="Application related issue" onClick={(e) => handleValue(e)}>Application related issue</Dropdown.Item>
                                                    <Dropdown.Item as="option" style={{ cursor: "pointer", color: "white" }} value="Other" onClick={(e) => handleValue(e)}>Other</Dropdown.Item>
                                                </DropdownButton>
                                                {/* <Form.Control.Feedback  type="invalid" style={{textAlign:"center"}}>
Please enter the Complaint name.
</Form.Control.Feedback> */}
                                            </Form.Group>
                                        </Col>
                                        <Col md="6" >
                                            <Form.Group controlId="formFile" className="mb-3">
                                                {/* <Form.Label style={{ display: "flex", color: "white" }}> Image</Form.Label>

        <Form.Control type="file" name='image' className="file mt-2" ></Form.Control> */}


                                                <div id="file-upload-form" className="uploader">
                                                    <input id="file-upload" type="file" name="fileUpload" accept="image/*" onChange={(e) => setImage(e.target.files[0])} />

                                                    <label for="file-upload" id="file-drag">
                                                        <img id="file-image" src="#" alt="Preview" className="hidden" />
                                                        <div id="start">
                                                            {/* <i className="fa fa-download" aria-hidden="true"></i> */}
                                                            <div className='mt-3'>Select an image or drag here</div>
                                                            {/* <div id="notimage" className="hidden">Please select an image</div> */}
                                                            <span id="file-upload-btn" className="btn btn-primary">Select Image{image?.target?.value}</span><br />
                                                            <span id="file-upload-btn" className="">{image?.name}</span>

                                                        </div>
                                                        <div id="response" className="hidden">
                                                            <div id="messages"></div>
                                                            <progress className="progress" id="file-progress" value="0">
                                                                <span>0</span>%
                                                            </progress>
                                                        </div>
                                                    </label>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col md="12" >
                                            <Form.Group style={{ width: "100%" }}>
                                                <Form.Label style={{ display: "flex", color: "white" }}> Description</Form.Label>

                                                <Form.Control as="textarea" name='description' id="exampleInputEmail2" required></Form.Control>

                                                {/* <Form.Control.Feedback  type="invalid" style={{textAlign:"center"}}>
Please enter the Complaint name.
</Form.Control.Feedback> */}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {complaint_message?.message && messageTimeout && (complaint_message?.statuscode == 200 ?
                                        <Alert variant="success" style={{ background: "rgb(0,255,0,0.1)", borderColor: "rgb(0,255,0)" }} >
                                            <p style={{ color: "rgb(0,255,0)", fontWeight: "500", fontSize: "14px" }}>
                                                {complaint_message?.message}
                                            </p>
                                        </Alert>
                                        : <Alert variant="danger" style={{ background: "rgb(255,0,0,0.1)", borderColor: "rgb(255,0,0)" }} >
                                            <p style={{ color: "rgb(255,0,0)", fontWeight: "500", fontSize: "14px" }}>
                                                {complaint_message?.message}
                                            </p>
                                        </Alert>)}
                                    <Button type='submit' style={{ display: "block", margin: "0 auto" }} className="btn btn-hover" >Submit</Button>
                                </Form>
                            </div>
                            {/* <h5 className="main-title mb-2 mt-3">Plan Details</h5> */}
                            {/* <div className="mt-2" style={{ border: "1px solid #ffffff", padding: "20px", borderRadius: "0.7rem" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        Current Plan : <span style={{ color: "yellow" }}>Basic</span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        Expired On : <span style={{ color: "yellow" }}>03/04/2024</span>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Link to="/price-plan" className="btn btn-hover iq-button" style={{ width: "100%" }}>Upgrade Plan</Link>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <div style={{ display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem" }}>
                                            <Grid item style={{ textAlign: "end" }} xs={4}>
                                                <LockOpenIcon />
                                            </Grid>
                                            <Grid item style={{ textAlign: "left" }} xs={8}>
                                                <span className='pl-2'>Change Password</span>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div style={{ display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem" }}>
                                            <Grid item style={{ textAlign: "end" }} xs={4}>
                                                <GroupIcon />
                                            </Grid>
                                            <Grid item style={{ textAlign: "left" }} xs={8}>
                                                <span className='pl-2'>Manage Profiles</span>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div style={{ display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem" }}>
                                            <Grid item style={{ textAlign: "end" }} xs={4}>
                                                <ReceiptLongIcon />
                                            </Grid>
                                            <Grid item style={{ textAlign: "left" }} xs={8}>
                                                <span className='pl-2'>Transaction History</span>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div style={{ display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem" }}>
                                            <Grid item style={{ textAlign: "end" }} xs={4}>
                                                <DevicesIcon />
                                            </Grid>
                                            <Grid item style={{ textAlign: "left" }} xs={8}>
                                                <span className='pl-2'>Device Management</span>
                                            </Grid>
                                        </div>
                                    </Grid>

                                </Grid>
                            </div> */}
                        </Col>
                    </Row>
                </Container>
            </section>
            <ThemeProvider theme={theme}>
                <ChatBot

                    // This appears as the header
                    // text for the chat bot
                    headerTitle="Premiumflix"
                    steps={steps}
                    {...config}
                    className="chat-bot"

                />
            </ThemeProvider>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Complaint)