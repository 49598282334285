import React, { useEffect, useState } from 'react'
import { Container, Col, Row, Nav, Tab, Card, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';

import * as MovieAction from '../../../actions/MovieAction'
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { ROUTES } from '../../../constant/routes';
import rent_icon from '../../../assets/regal_images/Rent (1).png'
import design from '../../../assets/regal_images/divider.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function Category(props) {
    const history = useHistory()
    // console.log("props",props)
    return (
        <>
            {
                props?.category?.data?.length ?
                    <div className='main-content'>
                        <section id="iq-favorites">
                            <Container fluid>
                                <Row>
                                    <Col sm="12" className="overflow-hidden">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                {/* <img src={design} className='mr-2' alt='design' style={{width:"10%"}}/> */}
                                                {/* <h6 className={"main-title " + (props?.category?.name == "Recently Launched" && "btn-color")} style={{ textTransform: "uppercase" }}>{props?.category?.name || "Loading"}</h6> */}
                                                <h6 className={"main-title "} style={{ textTransform: "uppercase" }}>{props?.category?.name || "Loading"}</h6>

                                            </div>
                                            {
                                                props?.category?.data?.length > 5 ?
                                                    <div
                                                        className="iq-view-all"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => history.push(ROUTES.viewAll, { data: props })}
                                                    >
                                                        View All
                                                    </div>
                                                    :
                                                    <></>
                                            }

                                        </div>
                                        <div id="favorites-contens">
                                            {/* <div id="prev" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                        <div id="next" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                                            <Swiper
                                                navigation={{
                                                    prevEl: '#prev',
                                                    nextEl: '#next'
                                                }}
                                                breakpoints={{
                                                    320: { slidesPerView: 2 },
                                                    562: { slidesPerView: 3 },
                                                    638: { slidesPerView: 3 },
                                                    810: { slidesPerView: 4 },
                                                    // 991: { slidesPerView: 4 },
                                                    1048: { slidesPerView: 5 },
                                                    1300: { slidesPerView: 6 },
                                                    1800: { slidesPerView: 7 }
                                                    // 1400: { slidesPerView: 4 },
                                                    // 1800: { slidesPerView: 5 },
                                                }}
                                                // loop={(movies?.length > 4) ? true : false}
                                                loop={false}
                                                slidesPerView={6}
                                                spaceBetween={0}
                                                as="ul"
                                                id={'slider-' + props?.category?.name}
                                                onTransitionEnd={(slider) => {
                                                    var view_all = document.getElementById("view-all-" + props?.category?.name)
                                                    if ((slider?.virtualSize + slider?.translate) == slider?.size) {
                                                        view_all.style.display = "block"
                                                    }
                                                    else {
                                                        view_all.style.display = "none"

                                                    }
                                                }}
                                                className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction">
                                                {
                                                    props?.category?.data?.length ?

                                                        props?.category?.data?.map((data, index) => (
                                                            <SwiperSlide as="li" key={index}>
                                                                <Link to={`${ROUTES.moviedetails}/${data?.slug}`}>
                                                                    <img src={`${IMAGE_ENDPOINT}/${data?.movie_thumbnail}`} className={`thumb-img`} alt={data?.movie_name} />
                                                                    {/* {data?.movie_access == "TVOD" && <div> <div className="block-icon-tvod">
                                                                        <img src={rent_icon} width={"75px"} />
                                                                    </div>


                                                                    </div>} */}
                                                                    {
                                                                        data?.leaving_soon == "true" && <div className='leaving-soon'>
                                                                            Leaving soon
                                                                        </div>
                                                                    }

                                                                    <Card className='hover-class'>
                                                                        <Card.Img variant="top" src={`${IMAGE_ENDPOINT}/${data?.movie_poster}`} />
                                                                        <Card.Body style={{ marginTop: "-1rem" }}>
                                                                            <span style={{ color: "#ffffff", fontSize: "13px" }}>{data?.movie_name}
                                                                            </span><br />
                                                                            <div style={{ marginTop: "-0.3rem" }}>
                                                                                <span style={{ color: "#ffffff", fontSize: "10px" }}>Duration: {data?.movie_duration}
                                                                                </span>
                                                                                <p style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: data?.movie_description?.length <= 60 ? data?.movie_description : data?.movie_description?.substring(0, 60) + "..." }}></p>
                                                                                <div style={{ display: "flex", background: "#ffffff", color: "#000000", fontWeight: "bolder", borderRadius: "0.5rem", }}>
                                                                                    <div style={{ fontSize: "13px", margin: "0 auto", padding: "5px" }}><i className="fa fa-info" aria-hidden="true"></i>  Details</div>
                                                                                </div>
                                                                            </div>

                                                                        </Card.Body>
                                                                    </Card>
                                                                </Link>
                                                            </SwiperSlide>


                                                        ))

                                                        :
                                                        <></>
                                                }

                                            </Swiper>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </div>
                    :
                    <></>
            }

        </>
    )
}
