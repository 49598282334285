import React from 'react'
// import axios from 'axios'
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'
import * as PaymentAction from '../../../actions/PaymentActions'

const Blog = () => {
    const dispatch = useDispatch()
    const { phonepePayment } = PaymentAction

    let data = {
        name: "harshil",
        amount: 1,
        number: '9104592065',
        email: "pharshil5603@gmail.com"
        // MID: 'MID' + Date.now() + "HARSHIL",
        // transactionId: 'T' + Date.now() + "HARSHIL"
    }
    const handleClick = async () => {

        const resData = await phonepePayment(data)
         if (resData?.data?.success) {
            window.location.href = resData?.data?.data?.instrumentResponse?.redirectInfo?.url
        }
        // try {
        //     await axios.post('http://localhost:8000/order', data).then(resp => {
        //         console.log(resp.data)
        //     }).catch(err => {
        //         console.log("err", err)
        //     })
        // }
        // catch (error) {
        //     console.log("error", error)
        // }
        // if (res?.data?.success) {
        //     window.location.href = res?.data?.data?.instrumentResponse?.redirectInfo?.url
        // }
    }
    return (
        <>
            <div className="m-profile">
                <button onClick={handleClick}>Pay now</button>
            </div>

        </>
    )
}

export default Blog