import axios from "axios";
import { ADD_CONTINUE_WATCHING, ADD_CONTINUE_WATCHING_ERROR, ADD_MOVIE_IN_WATCHLIST, ADD_MOVIE_IN_WATCHLIST_ERROR, GET_ALL_MOVIES, GET_ALL_MOVIES_BY_GENRE, GET_ALL_MOVIES_BY_GENRE_ERROR, GET_ALL_MOVIES_BY_LANGUAGE, GET_ALL_MOVIES_BY_LANGUAGE_ERROR, GET_ALL_MOVIES_ERROR, GET_ALL_SERIES, GET_ALL_SERIES_ERROR, GET_CONTINUE_WATCHING, GET_CONTINUE_WATCHING_ERROR, GET_LEAVING_SOON_MOVIES, GET_LEAVING_SOON_MOVIES_ERROR, GET_RECOMMENDED_MOVIES, GET_RECOMMENDED_MOVIES_ERROR, GET_USER_WATCHLIST, GET_USER_WATCHLIST_ERROR } from "../Actiontype/ActionType";
import { ADD_CONTINUE_WATCHING_API, ADD_MOVIE_IN_WATCHLIST_API, DELETE_CONTINUE_WATCHING_API, GET_ALL_MOVIES_API, GET_ALL_MOVIES_BY_GENRE_API, GET_ALL_MOVIES_BY_LANGUAGE_API, GET_ALL_SERIES_API, GET_CONTINUE_WATCHING_API, GET_LEAVING_SOON_MOVIES_API, GET_RECOMMENDED_MOVIES_API, GET_USER_WATCHLIST_API, LIKED_CONTENT_API, LIVE_MOVIE_COUNT_API, REMOVE_MOVIE_FROM_WATCHLIST_API, SEARCH_API, SET_TVOD_MOVIE_HOURS_API, advertise_seen_count_change_api } from "../constant/ApiList";
import { change_running_status } from "./HomeActions";
import { Base64 } from "js-base64";


export const getUserWatchlist = (userObj) => {
    return async (dispatch) => {
        return await axios
            .post(GET_USER_WATCHLIST_API, userObj).then((res) => {
                dispatch({
                    type: GET_USER_WATCHLIST,
                    payload: res.data
                })
                return res.data
            }).catch((error) => {
                dispatch({
                    type: GET_USER_WATCHLIST_ERROR
                })
                return error.response
            })
    }
}

export const addToLikedContent = async (userObj) => {
    return await axios
        .post(LIKED_CONTENT_API, userObj)
        .then((res) => {
            return res.data
        }).catch((error) => {
            return error.response
        })
}

export const getAllMovies = (userObj) => {
    return async (dispatch) => {
        const res = await axios.get("https://api.ipify.org/?format=json");

        return await axios
            .post(GET_ALL_MOVIES_API, { ...userObj, ip_address: res.data.ip }).then((res) => {
                dispatch({
                    type: GET_ALL_MOVIES,
                    payload: res.data
                })
                return { ...res?.data, data: JSON.parse(Base64.decode(res?.data?.data)) }
            }).catch((error) => {
                dispatch({
                    type: GET_ALL_MOVIES_ERROR
                })
                return error.response
            })
    }
}

export const getAllSeries = (userObj) => {
    return async (dispatch) => {
        return await axios
            .post(GET_ALL_SERIES_API, userObj).then((res) => {
                dispatch({
                    type: GET_ALL_SERIES,
                    payload: res.data
                })
                return { ...res?.data, data: JSON.parse(Base64.decode(res?.data?.data)) }
            }).catch((error) => {
                dispatch({
                    type: GET_ALL_SERIES_ERROR
                })
                return error.response
            })
    }
}

export const getContinueWatchingData = (userObj) => {
    return async (dispatch) => {
        return await axios
            .post(GET_CONTINUE_WATCHING_API, userObj).then((res) => {
                dispatch({
                    type: GET_CONTINUE_WATCHING,
                    payload: res.data
                })
                return res.data
            }).catch((error) => {
                dispatch({
                    type: GET_CONTINUE_WATCHING_ERROR
                })
                return error.response
            })
    }
}

export const deleteContinueWatching = async (userObj) => {
    return await axios
        .post(DELETE_CONTINUE_WATCHING_API, userObj)
        .then((res) => {
            return res.data
        }).catch((error) => {
            return error.response
        })
}


export const getAllMoviesByLanguage = (formData) => {
    return async (dispatch) => {
        return await axios
            .post(GET_ALL_MOVIES_BY_LANGUAGE_API, formData).then((res) => {
                dispatch({
                    type: GET_ALL_MOVIES_BY_LANGUAGE,
                    payload: res.data
                })
                return { ...res?.data, data: JSON.parse(Base64.decode(res?.data?.data)) }
            }).catch((error) => {
                dispatch({
                    type: GET_ALL_MOVIES_BY_LANGUAGE_ERROR
                })
                return error.response
            })
    }
}

export const getAllMoviesByGenre = (formData) => {
    return async (dispatch) => {
        return await axios
            .post(GET_ALL_MOVIES_BY_GENRE_API, formData)
            .then((res) => {
                dispatch({
                    type: GET_ALL_MOVIES_BY_GENRE,
                    payload: res.data
                })
                return { ...res?.data, data: JSON.parse(Base64.decode(res?.data?.data)) }
            }).catch((error) => {
                dispatch({
                    type: GET_ALL_MOVIES_BY_GENRE_ERROR
                })
                return error.response
            })
    }
}

export const addIntoWatchList = (userObj) => {
    return async (dispatch) => {
        return await axios
            .post(ADD_MOVIE_IN_WATCHLIST_API, userObj)
            .then((res) => {
                dispatch({
                    type: ADD_MOVIE_IN_WATCHLIST,
                    payload: res.data
                })
                return res.data
            }).catch((error) => {
                dispatch({
                    type: ADD_MOVIE_IN_WATCHLIST_ERROR
                })
                return error.response
            })
    }
}


export const removeFromWatchlist = async (userObj) => {
    return await axios
        .post(REMOVE_MOVIE_FROM_WATCHLIST_API, userObj)
        .then((res) => {
            return res.data
        }).catch((error) => {
            return error.response
        })
}

export const getSearchSuggestion = async (userObj) => {
    const res = await axios.get("https://api.ipify.org/?format=json");

    return await axios
        .post(SEARCH_API, { ...userObj, ip_address: res.data.ip })
        .then((res) => {
            return res.data
        }).catch((error) => {
            return error.response
        })
}

export const advertise_seen_count_change = (formData) => async () => {
    try {
        const res = await axios.get("https://api.ipify.org/?format=json");

        const { data } = await advertise_seen_count_change_api({ ...formData, ip_address: res.data.ip });



    } catch (error) {
    }
};


export const live_movie_view_count = async (userObj) => {
    return await axios
        .post(LIVE_MOVIE_COUNT_API, userObj)
        .then((res) => {
            return res.data
        }).catch((error) => {
            return error.response
        })
}

export const addContinueWatching = (userObj) => {
    return async (dispatch) => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        dispatch(
            change_running_status({
                user: userObj?.user,
                login_type: "Web",
                running_status: "Stop",
                ip_address: res.data.ip,
                movie_id: userObj?.movie,
                episode_id: userObj?.episode,
                content_type: userObj?.select_type
            }),
            live_movie_view_count({ movie: userObj?.movie, status: "Stop" })
        )

        return await axios
            .post(ADD_CONTINUE_WATCHING_API, userObj).then((res) => {

                dispatch({
                    type: ADD_CONTINUE_WATCHING,
                    payload: res.data
                })
                return res.data
            }).catch((error) => {
                dispatch({
                    type: ADD_CONTINUE_WATCHING_ERROR
                })
                return error.response
            })

        if (userObj?.movie) {
            dispatch(live_movie_view_count({ movie: userObj?.movie, status: "Stop" }))
        }



    }
}


export const setCompleteHours = async (userObj) => {
    return await axios
        .post(SET_TVOD_MOVIE_HOURS_API, userObj)
        .then((res) => {
            return res.data
        }).catch((error) => {
            return error.response
        })
}

export const getRecommendedMovies = (userObj) => {
    return async (dispatch) => {
        return await axios
            .post(GET_RECOMMENDED_MOVIES_API, userObj).then((res) => {
                dispatch({
                    type: GET_RECOMMENDED_MOVIES,
                    payload: res.data
                })
                return res.data
            }).catch((error) => {
                dispatch({
                    type: GET_RECOMMENDED_MOVIES_ERROR
                })
                return error.response
            })
    }
}

export const getLeavingSoonMovies = (userObj) => {
    return async (dispatch) => {
        return await axios
            .post(GET_LEAVING_SOON_MOVIES_API, userObj).then((res) => {
                dispatch({
                    type: GET_LEAVING_SOON_MOVIES,
                    payload: res.data
                })
                return res.data
            }).catch((error) => {
                dispatch({
                    type: GET_LEAVING_SOON_MOVIES_ERROR
                })
                return error.response
            })
    }
}