import React, { useState, useEffect, useMemo } from 'react'
import { Container, Button, Row, Col, Form } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import FormControl from "@mui/material/FormControl";
import InputGroup from 'react-bootstrap/InputGroup';

import styles from "./form.module.css";
import "./form.css"
// rtl 
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { rtlModeAction, getRtlMode } from '../../../../store/mode/rtlmode'
import { useDispatch } from 'react-redux';
import { forgot_password_send_otp, forgot_password_verify_otp, logout } from "../../../../actions/HomeActions"
import { useSelector } from 'react-redux';
import bg from "../../../../assets/images/signup_background.jpg"
import Alert from 'react-bootstrap/Alert';
import OtpInput from 'react-otp-input';
import { CHANGE_PASSWORD_MESSAGE } from '../../../../Actiontype/ActionType';
import { ROUTES } from '../../../../constant/routes';
import { CircularProgress } from '@mui/material';
import * as HomeAction from "../../../../actions/HomeActions"
import { toast } from 'react-toastify';


const mapStateToProps = (state) => {
    return {
        rtlMode: getRtlMode(state)
    };


}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            rtlModeAction
        },
        dispatch
    )
})
const Forgot_Password = (props) => {

    const user = JSON.parse(sessionStorage.getItem('user'))
    const dispatch = useDispatch()
    const [form, setForm] = useState({ flag: "change_password" })
    const [messageTimeout, setMessageTimeout] = useState(false)
    let history = useHistory()
    const [show, setShow] = useState(false);
    const [view, setView] = useState("enter_username")
    const [seconds, setSeconds] = useState(0);
    const resendOTPDuration = 60
    const { change_password } = bindActionCreators(HomeAction, dispatch)
    useEffect(() => {
        // Exit early if countdown is finished
        if (seconds <= 0) {
            return;
        }

        // Set up the timer
        const timer = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);

        // Clean up the timer
        return () => clearInterval(timer);
    }, [seconds]);
    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60)
            .toString()
            .padStart(2, '0');
        const seconds = (timeInSeconds % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };
    useEffect(() => {
        const rtlMode = sessionStorage.getItem('rtl-mode');
        if (rtlMode === null) {
            props.rtlModeAction(props.rtlMode)
        }
        else {
            props.rtlModeAction(rtlMode);
        }
    })
    const forgot_password_message = useSelector((state) => state.home.forgot_password_message);
    const change_password_message = useSelector((state) => state.home.change_password_message);
    useMemo(() => {
        if (change_password_message?.statuscode == 200) {
            dispatch(logout({ user: user?.id }))
            setTimeout(() => {
                history.push("/login")

            }, 2000);
            dispatch({ type: CHANGE_PASSWORD_MESSAGE, payload: null });
        }
    }, [change_password_message])
    useMemo(() => {
        setMessageTimeout(true)
        if (forgot_password_message?.statuscode == 200) {
            if (view == "enter_username" || form?.resend) {
                setView("enter_otp")
                setSeconds(resendOTPDuration)
            }
            else if (view == "enter_otp" && !form?.resend)
                setView("enter_new_password")

        }
        else {
            setForm({ ...form, processing: false })
        }

        setTimeout(() => { setMessageTimeout(false) }, 2000)
    }, [forgot_password_message])

    const handle_Forgot_Password_Send_OTP_Submit = (event, resend) => {
        event.preventDefault();

        dispatch(forgot_password_send_otp({ ...form }));
        setForm({ ...form, processing: true, resend: resend })
    }
    const handle_forgot_password_verify_otp_Submit = (event) => {
        event.preventDefault();
        setForm({ ...form, resend: false })

        dispatch(forgot_password_verify_otp({ ...form }));
    }
    const handle_Update_Password_Submit = async (event) => {
        event.preventDefault(); 
        // if (form?.password == form?.rpassword)
        // dispatch(change_password({ ...form }));
        if (form?.new_password == form?.rpassword) {
            const resData = await change_password({ ...form })
            if (resData?.statuscode == 200) {
                toast.success(resData?.message)
            }
            else {
                toast.error(resData?.message)
            }
        }


    }

    const goBack = () => {
        history.push(ROUTES.my_account)
    }

    return (
        <>
            {/* <div className={`rtl-box ${show === true ? 'show' : ''}`} >
                <button type="button" className="btn btn-light rtl-btn">
                    <svg onClick={() => setShow(!show)} xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 20 20" fill="white">
                        <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                    </svg>
                </button>
                <div className="rtl-panel">
                    <ul className="modes">
                        <li className="dir-btn" data-mode="rtl" data-active="false" onClick={() => { props.rtlModeAction('ltl') }} data-value="ltr"><Link to="#">LTR</Link></li>
                        <li className="dir-btn" data-mode="rtl" data-active="true" onClick={() => { props.rtlModeAction('rtl') }} data-value="rtl"><Link to="#">RTL</Link></li>
                    </ul>
                </div>
            </div> */}
            {/* <section className="sign-in-page"
                style={{ backgroundImage: "url(" + bg + ")" }}
            > */}
            <section className="sign-in-page"
            // style={{ backgroundImage: "url(" + bg + ")" }}
            >
                <Container >
                    <Row className="justify-content-center align-items-center height-self-center">
                        <Col lg="7" md="12" className="align-self-center">
                            <div className="sign-user_card ">
                                <div className="sign-in-page-data">
                                    <div className="sign-in-from w-100 m-auto">
                                        <p style={{ textAlign: "center", marginBottom: "1rem", fontWeight: "400", fontSize: "24px" }}>Change Password</p>

                                        {view == "enter_otp" && <Form onSubmit={(event) => handle_forgot_password_verify_otp_Submit(event)}>

                                            <Row style={{ display: "flex", justifyContent: "center" }}>
                                                <Col md="6">
                                                    <Form.Group >
                                                        <Form.Label style={{ display: "flex", justifyContent: "center" }}>Please enter the OTP</Form.Label>
                                                        <FormControl fullWidth >
                                                            <OtpInput
                                                                containerStyle={{ display: "flex", justifyContent: "space-evenly" }}
                                                                value={form?.OTP}
                                                                onChange={(a) => (setForm({ ...form, OTP: a }))}
                                                                numInputs={4}
                                                                inputStyle={{ fontSize: "3em", padding: "0", border: "none", borderRadius: "0", borderBottom: "2px solid white", background: "transparent" }}

                                                                inputType='text'
                                                                renderSeparator={<span> &nbsp;&nbsp; </span>}
                                                                renderInput={(props) => <input {...props} />}
                                                            />
                                                        </FormControl>
                                                    </Form.Group>
                                                    {forgot_password_message?.message && messageTimeout && (forgot_password_message?.statuscode == 200 ?
                                                        <Alert variant="success" style={{ background: "rgb(0,255,0,0.1)", borderColor: "rgb(0,255,0)" }} >
                                                            <p style={{ color: "rgb(0,255,0)", fontWeight: "500", fontSize: "14px" }}>
                                                                {forgot_password_message?.message}
                                                            </p>
                                                        </Alert>
                                                        : <Alert variant="danger" style={{ background: "rgb(255,0,0,0.1)", borderColor: "rgb(255,0,0)" }} >
                                                            <p style={{ color: "rgb(255,0,0)", fontWeight: "500", fontSize: "14px" }}>
                                                                {forgot_password_message?.message}
                                                            </p>
                                                        </Alert>)}
                                                    {seconds > 0 ? <p style={{ textAlign: "center" }}>Resend OTP in {formatTime(seconds)}</p> : <p style={{ cursor: "pointer", textAlign: "center" }} onClick={(event) => {
                                                        handle_Forgot_Password_Send_OTP_Submit(event, true)
                                                    }} >Resend OTP</p>}
                                                </Col>



                                            </Row>
                                            <div style={{ display: "flex", justifyContent: "space-around" }}>

                                                <Button type='submit' className="btn btn-hover" >Submit</Button>
                                            </div>
                                        </Form>}
                                        {view == "enter_username" && <Form onSubmit={(event) => handle_Forgot_Password_Send_OTP_Submit(event)}>


                                            <Row style={{ display: "flex", justifyContent: "center" }}>
                                                <Col md="6">
                                                    <Form.Group>
                                                        <Form.Label>Mobile No / Email</Form.Label>
                                                        <Form.Control type="text" onChange={(event) => (setForm({ ...form, username: event.target.value }))} className="mb-0" id="exampleInputPassword2" placeholder="Mobile No / Email" required />

                                                    </Form.Group>
                                                    {forgot_password_message?.message && messageTimeout && <Alert variant="danger" style={{ background: "rgb(255,0,0,0.1)", borderColor: "rgb(255,0,0)" }} >
                                                        <p style={{ color: "rgb(255,0,0)", fontWeight: "500", fontSize: "14px" }}>
                                                            {forgot_password_message?.message}
                                                        </p>
                                                    </Alert>}
                                                </Col>
                                            </Row>
                                            {form?.processing ? <p style={{ textAlign: "center" }}><CircularProgress /></p> : <Button type='submit' style={{ display: "block", margin: "0 auto" }} className="btn btn-hover" >Submit</Button>}
                                            <div style={{ cursor: "pointer",textAlign:"center" }} className='mt-2' onClick={goBack}><i className="fa fa-arrow-left" aria-hidden="true"></i>  Back</div>
                                        </Form>}
                                        {view == "enter_new_password" && <Form onSubmit={(event) => handle_Update_Password_Submit(event)}>

                                            <Row style={{ display: "flex", justifyContent: "center" }}>
                                                <Col md="6">
                                                    <Form.Group>

                                                        <Form.Label style={{ color: "white" }}>Old Password</Form.Label>
                                                        <InputGroup className="mb-3">
                                                            <Form.Control type="password" onChange={(event) => (setForm({ ...form, old_password: event.target.value }))} className="promocode-input" id="old_password" placeholder="Old Password" required />
                                                            <Button variant="outline-secondary" onClick={(e) => {
                                                                let temp = document.getElementById("old_password")
                                                                if (temp.type == "password") {
                                                                    temp.type = "text"
                                                                    document.getElementById("old_password_icon").className = "fa fa-eye"
                                                                }
                                                                else {
                                                                    temp.type = "password"
                                                                    document.getElementById("old_password_icon").className = "fa fa-eye-slash"

                                                                }
                                                            }} className='promocode-button'>
                                                                <i className="fa fa-eye-slash" id="old_password_icon" style={{ color: "#ffffff" }}></i>
                                                            </Button>      </InputGroup>
                                                    </Form.Group>
                                                </Col>



                                            </Row>
                                            <Row style={{ display: "flex", justifyContent: "center" }}>
                                                <Col md="6">
                                                    <Form.Group>
                                                        <Form.Label style={{ color: "white" }}>New Password</Form.Label>
                                                        <InputGroup className="mb-3">

                                                            <Form.Control type="password" isInvalid={form?.old_password && form?.old_password == form?.new_password} onChange={(event) => (setForm({ ...form, new_password: event.target.value }))} className="promocode-input" id="new_password" placeholder="New Password" required />
                                                            <Button variant="outline-secondary" onClick={(e) => {
                                                                let temp = document.getElementById("new_password")
                                                                if (temp.type == "password") {
                                                                    temp.type = "text"
                                                                    document.getElementById("new_password_icon").className = "fa fa-eye"
                                                                }
                                                                else {
                                                                    temp.type = "password"
                                                                    document.getElementById("new_password_icon").className = "fa fa-eye-slash"

                                                                }
                                                            }} className='promocode-button'>
                                                                <i className="fa fa-eye-slash" id="new_password_icon" style={{ color: "#ffffff" }}></i>
                                                            </Button>
                                                            <Form.Control.Feedback type="invalid">
                                                                New Passwords cannot be same as old password.
                                                            </Form.Control.Feedback>      </InputGroup>
                                                    </Form.Group>
                                                </Col>



                                            </Row>
                                            <Row style={{ display: "flex", justifyContent: "center" }}>
                                                <Col md="6">
                                                    <Form.Group>
                                                        <Form.Label style={{ color: "white" }}>Confirm Password</Form.Label>
                                                        <InputGroup className="mb-3">

                                                            <Form.Control type="password" isInvalid={form?.rpassword != form?.new_password} onChange={(event) => (setForm({ ...form, rpassword: event.target.value }))} className="promocode-input" id="confirm_password" placeholder="Confirm Password" required />
                                                            <Button variant="outline-secondary" onClick={(e) => {
                                                                let temp = document.getElementById("confirm_password")
                                                                if (temp.type == "password") {
                                                                    temp.type = "text"
                                                                    document.getElementById("confirm_password_icon").className = "fa fa-eye"
                                                                }
                                                                else {
                                                                    temp.type = "password"
                                                                    document.getElementById("confirm_password_icon").className = "fa fa-eye-slash"

                                                                }
                                                            }} className='promocode-button'>
                                                                <i className="fa fa-eye-slash" id="confirm_password_icon" style={{ color: "#ffffff" }}></i>
                                                            </Button>
                                                            <Form.Control.Feedback type="invalid">
                                                                Passwords do not match.
                                                            </Form.Control.Feedback>      </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row style={{ display: "flex", justifyContent: "center" }}>
                                                <Col md="6">
                                                    {forgot_password_message?.message && messageTimeout && (forgot_password_message?.statuscode == 200 ?
                                                        <Alert variant="success" style={{ background: "rgb(0,255,0,0.1)", borderColor: "rgb(0,255,0)" }} >
                                                            <p style={{ color: "rgb(0,255,0)", fontWeight: "500", fontSize: "14px" }}>
                                                                {forgot_password_message?.message}
                                                            </p>
                                                        </Alert>
                                                        : <Alert variant="danger" style={{ background: "rgb(255,0,0,0.1)", borderColor: "rgb(255,0,0)" }} >
                                                            <p style={{ color: "rgb(255,0,0)", fontWeight: "500", fontSize: "14px" }}>
                                                                {forgot_password_message?.message}
                                                            </p>
                                                        </Alert>)}
                                                </Col>
                                            </Row>


                                            <Button type='submit' style={{ display: "block", margin: "0 auto" }} className="btn btn-hover" >Submit</Button>

                                        </Form>}
                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Forgot_Password)