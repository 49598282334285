import React,{useState, useEffect, useMemo}  from 'react'
import { Link,useHistory } from 'react-router-dom'
import "./form.css"

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import OtpInput from 'react-otp-input';

import bg from "../../../../assets/images/Bg.jpg"
import { IMAGE_ENDPOINT } from '../../../../constant/ApiList';
import {  Form } from 'react-bootstrap'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import FormControl from "@mui/material/FormControl";
import { PROFILE_MESSAGE } from '../../../../Actiontype/ActionType';
import { forgot_profile_pin, profile_pin_verification } from '../../../../actions/HomeActions';


const ProfilePin = (props) => {
    const user_id=JSON.parse(sessionStorage.getItem('user'))?.id
const dispatch=useDispatch()
    let history = useHistory()
    const location=useLocation()
const [form,setForm]=useState({...location.state?.profile,profile_pin:""})
       
   const profile_message=useSelector((state)=>state.home.profile_message)
       const forgot_profile_pin_message=useSelector((state)=>state.home.forgot_profile_pin_message)
        const handleSubmit=()=>{
            dispatch({type:PROFILE_MESSAGE,payload:null})
    
            dispatch(profile_pin_verification({...form,user_id:form?.user,profile_id:form?.id}))
        
        }
        const handleForgotPin=()=>{
        
    
            dispatch(forgot_profile_pin({...form,profile:form?.id}))
        
        }
       useMemo(()=>{
        if(profile_message?.statuscode==200)
        {
        dispatch({type:PROFILE_MESSAGE,payload:null})
           
            if(form?.type=="profile_select")
            {sessionStorage.setItem("profile",JSON.stringify(form))
            history.push("/")}
            else if(form?.type=="profile_edit")
            history.push("/profile_edit",{profile:{...form,profileImage:IMAGE_ENDPOINT+form?.avatar?.avatarImage,avatar:form?.avatar?.id}})
            else if(form?.type=="profile_delete")
            history.push("/profile_manage",{delete_profile_id:form?.id})

        }
       },[profile_message])
         
    return (
        <div style={{
        backgroundSize:"cover"}}>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"100vh",padding:"0 10%",flexDirection:"column",
        background:"rgb(0,0,0,.5)",
        
        }}>
        <h1 style={{textAlign:"center",marginBottom:".5rem",fontWeight:"unset",fontSize:"3.5vw",color:"white"}}>Profile Lock</h1>
        <p>Please enter the 4 digit Pin</p>

        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <div style={{margin:"2rem",cursor:"pointer"}} >

                                                <img src={IMAGE_ENDPOINT+form?.avatar?.avatarImage}  style={{maxWidth:"150px"}}  className="rounded img-fluid d-block mx-auto mb-3" alt="user"/>
                                                <p style={{textAlign:"center"}}>{form?.profileName||"No Name"}</p>
                                                <Form.Group >
                                                        <FormControl fullWidth >
                                                            <OtpInput
                                                                containerStyle={{ display: "flex", justifyContent: "space-evenly" }}
                                                                value={form?.profile_pin}
                                                                onChange={(a) => (setForm({ ...form, profile_pin: a }))}
                                                                numInputs={4}
                                                                inputStyle={{ width:"2.5em",height:"2.5em",fontSize: "1em", padding: ".5em",  borderRadius: ".5rem",borderWidth:"2px",  background: "transparent" }}
                                                                inputType='tel'
                                                                renderSeparator={<span> &nbsp;&nbsp; </span>}
                                                                renderInput={(props) => <input {...props} />}
                                                            />
                                                           </FormControl>
                                                           {profile_message?.statuscode==406 && <Form.Control.Feedback style={{ display: "flex", justifyContent: "center",textAlign:"center" }} type="invalid">
                                                                {profile_message?.message}
                                                            </Form.Control.Feedback>}
                                                    </Form.Group>
                                                </div>
        
        </div>
        <button onClick={handleSubmit}  className="btn btn-hover iq-button">Submit</button>
        <p className='mt-2'>{forgot_profile_pin_message?.message}</p>
        <p className='mt-2' onClick={handleForgotPin} style={{cursor:"pointer"}}>Forgot Pin</p>

        </div>
        </div>
    )
}

export default ProfilePin