import React, { useEffect, useRef, useState } from 'react'
import { Container, Col, Row, Nav, Tab, Card, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';

import fav1 from '../../../assets/images/favorite/01.jpg'
import fav2 from '../../../assets/images/favorite/02.jpg'
import fav3 from '../../../assets/images/favorite/03.jpg'
import fav4 from '../../../assets/images/favorite/04.png'
import fav5 from '../../../assets/images/favorite/05.jpg'
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import * as MovieAction from '../../../actions/MovieAction'
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { ROUTES } from '../../../constant/routes';
import rent_icon from '../../../assets/regal_images/Rent (1).png'
import { Base64 } from 'js-base64';

export default function RecommendedMovies() {
  const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
  const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
  const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))

  const dispatch = useDispatch()
  const { getRecommendedMovies } = bindActionCreators(MovieAction, dispatch)
  const [movie, setMovies] = useState("")
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        videoRef.current.mute();

      } else {
        videoRef.current.play();
        videoRef.current.mute();

      }
      setIsPlaying(!isPlaying);
    }
  };


  useEffect(() => {
    (async () => {
      if (isLoggedIn) {
        const resData = await getRecommendedMovies({ user_id: user_id, profile_id: profile_id })
        if (resData?.data?.length) {
          setMovies({ ...resData, data: JSON.parse(Base64.decode(resData.data)) })

        }
      }

    })();
  }, [])
  return (
    <>
      {
        isLoggedIn && movie?.data?.length ?
          <div className='main-content'>
            <section id="iq-favorites">
              <Container fluid>
                <Row>
                  <Col sm="12" className="overflow-hidden">
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className={"main-title "} style={{ textTransform: "uppercase" }}>Because you watched  <span style={{ fontSize: "18px" }} className='btn-color'>"{movie?.movie_name}"</span></h6>
                    </div>
                    <div id="favorites-contens">
                      {/* <div id="prev4" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                <div id="next4" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                      <Swiper
                        navigation={{
                          prevEl: '#prev',
                          nextEl: '#next'
                        }}
                        breakpoints={{
                          320: { slidesPerView: 2 },
                          562: { slidesPerView: 3 },
                          638: { slidesPerView: 3 },
                          810: { slidesPerView: 4 },
                          // 991: { slidesPerView: 4 },
                          1048: { slidesPerView: 5 },
                          1300: { slidesPerView: 6 },
                          1800: { slidesPerView: 7 }
                          // 1400: { slidesPerView: 4 },
                          // 1800: { slidesPerView: 5 },
                      }}
                        loop={false}
                        slidesPerView={6}
                        spaceBetween={0}
                        as="ul"
                        className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction">

                        {
                          movie?.data?.length ?
                            movie?.data?.map((data, index) => (
                              <SwiperSlide as="li" key={index}>
                                <Link to={`${ROUTES.moviedetails}/${data?.slug}`}>
                                  <img src={`${IMAGE_ENDPOINT}/${data?.movie_thumbnail}`} className="thumb-img" alt={data?.movie_name} />
                                  {/* {data?.movie_access == "TVOD" && <div> <div className="block-icon-tvod">
                                    <img src={rent_icon} width={"75px"} />
                                  </div>


                                  </div>} */}
                                  <Card className='hover-class'>
                                    {/* <Card.Img variant="top" src={`${IMAGE_ENDPOINT}/${data?.movie_poster}`} /> */}
                                    <video style={{ width: "100%" }} ref={videoRef} poster={`${IMAGE_ENDPOINT}/${data?.movie_poster}`} onClick={togglePlayPause} loop autoPlay muted={true}>
                                      <source src={`${data?.free_preview1}`} type="video/mp4" />
                                      Your browser does not support the video tag.
                                    </video>
                                    <Card.Body style={{ marginTop: "-1rem" }}>
                                      <span style={{ color: "#ffffff", fontSize: "13px" }}>{data?.movie_name}
                                      </span><br />
                                      <div style={{ marginTop: "-0.3rem" }}>
                                        <span style={{ color: "#ffffff", fontSize: "10px" }}>Duration: {data?.movie_duration}
                                        </span>
                                        <p style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: data?.movie_description?.length <= 60 ? data?.movie_description : data?.movie_description?.substring(0, 60) + "..." }}></p>
                                        <div style={{ display: "flex", background: "#ffffff", color: "#000000", fontWeight: "bolder", borderRadius: "0.5rem", }}>
                                          <div style={{ fontSize: "13px", margin: "0 auto", padding: "5px" }}><i className="fa fa-info" aria-hidden="true"></i>  Details</div>
                                        </div>
                                      </div>

                                    </Card.Body>
                                  </Card>
                                </Link>
                              </SwiperSlide>
                            ))
                            :
                            <></>
                        }


                      </Swiper>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
          :
          <></>
      }

    </>
  )
}
